<template>
  <div class="container">
    <span class="title">{{pageContent.title}}</span>
    <ul class="star" v-if="initstar">
      <li  
      v-for="(item, index) in starNum"
      :key="index"
      :class="isRating(index)"
       @click.stop="clickRating"
      :data-index="index">
      </li>
    </ul>
    <!-- 1-4分显示 -->
    <!-- <div v-if="rating !== giftrate"> -->
    <div v-if="newversion ? rating < giftrate:true">
      <div class="lessfive">
        {{pageContent.lowtip}}
      </div>
      <div>
        <textarea class="content" placeholder="Your feedback or suggestion" v-model="form.lowcontent"></textarea>
      </div>
      <div class="pictip">{{pageContent.addpic}}</div>
      <!-- 上传图片 -->
      <upload-img class="uploadfa" v-bind:limit="3" @imgfile="getupload"></upload-img>

      <button class="send marginsend nofullsend" ref="Lowsend" @click="Tosend">{{pageContent.lowbutton}}</button>
    </div>

    <!-- 5分显示 -->
    <!-- <div v-else-if="rating == giftrate"> -->
    <div v-else-if="newversion && rating >= giftrate">
      <div class="fivetip">
        {{pageContent['5tip1']}}
      </div>
      <div class="guidamazon">
        {{pageContent['5tip2']}}
      </div>
      <div class="guidamazon">
        {{pageContent['5tip3']}}
      </div>
      
      <drop-station class="stationselect" @selectvalue="getStation" v-bind:stationData="stationData"></drop-station>

      <div style="width: 100%;margin: 0 auto">
        <button class="send marginsend sendcomment beforsend" ref="SendComment" @click="Tocomment">{{pageContent['5buuton1']}}</button>
        <button class="send marginsend gift" v-if="isCommen" @click="isRecieve">{{pageContent['5button2']}}</button>
      </div>
    </div>

    <!-- 是否领取礼物弹框 -->
    <!-- <div id="popLayer" @click="layer"></div>
    <div id="popBox">
      <img class="popimg" src="../assets/image/ic_pop_praise.png" alt="">
      <div class="poptip">After you log out, you will lose the opportunity to receive gifts. Do you want to continue to log out? </div>
      <div class="popbutton">
        <button class="button leftbutton" style="border-bottom-left-radius:10px">Go away</button>
        <router-link :to="'/gift/'+ checkedsite.id">
          <button class="button rightbutton" style="border-bottom-right-radius:10px">Continue to fill in</button>
        </router-link>
      </div>
    </div> -->

    <dialogue v-if="popdio" @closedio="closedio" v-bind:tiptype="tiptype" v-bind:feedbacksuccess="feedbacksuccess.feedbacksuccess" v-bind:isOver="isOver" v-bind:erromsg="erromsg"></dialogue>
    <gologin @gologin="getlogin" v-if="isLogin" v-bind:gologincontent="gologincontent" v-bind:gologinsure="gologinsure" v-bind:gologincancel="gologincancel"></gologin>
    <dialogue v-if="feederror" v-bind:tiptype="tiptype" v-bind:isOver="isOver" v-bind:erromsg="erromsg"></dialogue>
    <loading v-if="loadingshow"></loading>

  </div>
</template>

<script type="text/ecmascript-6">
import loading from '../components/loading.vue'
import gologin from '../components/goLogin'
import dialogue from '../components/dialogue'
import uploadImg from '../components/uploadimg'
import dropStation from '../components/dropselect'
import Cryptojs from 'crypto-js'
export default {
  name: '',
  mixins: [],
  components: {
    uploadImg,
    dropStation,
    dialogue,
    gologin,
    loading
  },
  props: {
    starNum: {
      // 星星的个数
      type: Number,
      default: 5,
    },
    defaultRating: {
      // 默认点亮的个数
      type: Number,
      default: 5,
    },
    outIndex: {
      // 外界传进来的一个标识
      type: Number,
      default: 0,
    },
    readOnly: {
      // 是否是只读，默认不是，拥有点击功能
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      pageContent: {},
      form: {
        lowcontent: '',
      },
      initstar: false,
      rating: null, // 用于控制点亮星星的个数
      giftrate: 5, // 领取奖品的评分限制
      isCommen: false,
      stationData: [],
      stationcontent: {},
      checkedsite: {},
      filelist: [],
      imgstringList: [],
      popdio: false,// 是否显示弹框
      tiptype: true,// 成功/失败
      erromsg: '',// 错误提示语
      // 登录提示框
      isLogin: false,
      cansavegrade1: false,
      cansavegrade2: false,
      getislogin: false,
      lancode: '',
      newversion: false,
      isOver: false,
      initrating: false,
      feedbacksuccess: {},
      gologincontent: '',
      gologinsure: '',
      gologincancel: '',
      feedfaild: '',
      feederror: false,
      loadingshow: false,
      feedOverStatus: false,

      // 签名信息
      signinfo: {
        uid: '',
        api_token: '',
        appid: 'd0a4667742d0e7d7',
        secret: '8c56b4dfd0a4667742d0e7d7712ea9af',
        username: '',
        phone_model: '',
        phone_system: '',
        camera_model: '',
        activity_id: '',
        product_id: '',
        unique_number: '',
        email: '',
        appversion: ''
      }
    }
  },
  computed: {
    isInput () {
      const { lowcontent } = this.form
      return { lowcontent }
    }
  },
  watch: {
    isInput (e) {
      if (e.lowcontent.trim() !== '') {
        this.$refs.Lowsend.classList.remove('nofullsend')
        this.$refs.Lowsend.classList.add('sendcolor')
      } else {
        this.$refs.Lowsend.classList.remove('sendcolor')
        this.$refs.Lowsend.classList.add('nofullsend')
      }
    }
  },
  methods: {
    GetsiteList () {
      let timestamp = Date.parse(new Date()).toString().substr(0,10)
      let sign = this.getSign(this.signinfo.appid,this.signinfo.secret,timestamp,'','')
      this.$axios.get('/v2/invite_comments/products_to_site',{
        params: {
          activity_product: this.signinfo.camera_model
        },
        headers: {
          'appid': this.signinfo.appid,
          'timestamp': timestamp,
          'sign': sign,
          'language': this.lancode
        }
      }).then(res => {
         if (res.data.code == 1000) {
          this.stationData = res.data.data
        }
      }).catch(error => {
        console.log(error)
      })
    },
    closedio (item) {
      this.popdio = false
      if (item) {
        this.feedbackOver()
      }
    },
    isRating (index) {
      ++index
      return index <= this.rating ? 'star-item star-active' : 'star-item'
    },
    clickRating (ev) {
      if (this.readOnly) {
        return
      }
      let mark = parseInt(ev.target.dataset.index) + 1
      this.rating = mark
      this.$emit('starMarkChange', mark, this.outIndex)
      if (this.rating!== 5) {
        this.isCommen = false
        // this.$refs.SendComment.classList.add('beforsend')
        // this.$refs.SendComment.classList.remove('aftersend')
      } else {
        this.eventTracking('00056','2031','104','','','')
      }
      this.saveScore()
    },
    saveScore () {
      // 保存评分---未登录
      if (this.cansavegrade1 && this.cansavegrade2) {
        let timestamp = Date.parse(new Date()).toString().substr(0,10)
        if (!this.signinfo.uid || !this.signinfo.api_token) {
          let sign = this.getSign(this.signinfo.appid,this.signinfo.secret,timestamp,'','')
          this.$axios.post('/v2/invite_comments/save_grade',{
            unique_number: this.signinfo.unique_number,
            camera_model: this.signinfo.camera_model,
            phone_model: this.signinfo.phone_model,
            phone_system: this.signinfo.phone_system,
            grade: this.rating,
            activity_id: +this.signinfo.activity_id
          },{
            headers: {
              // 'uid': this.signinfo.uid,
              // 'api-token': this.signinfo.api_token, 
              'appid': this.signinfo.appid,
              'timestamp': timestamp,
              'sign': sign,
              'language': this.lancode
            }
          }).then(res => {
            if (res.data.code == 1000) {
              return
            }
            this.popdio = true
            this.tiptype = false
            this.erromsg = res.data.msg
          }).catch(error => {
            console.log(error)
          })
        } else {
          // 登录保存
          let sign = this.getSign(this.signinfo.appid,this.signinfo.secret,timestamp,this.signinfo.uid,this.signinfo.api_token)
          this.$axios.post('/v2/invite_comments/user_save_grade',{
            unique_number: this.signinfo.unique_number,
            camera_model: this.signinfo.camera_model,
            phone_model: this.signinfo.phone_model,
            phone_system: this.signinfo.phone_system,
            grade: this.rating,
            activity_id: +this.signinfo.activity_id
          },{
            headers: {
              'uid': this.signinfo.uid,
              'api-token': this.signinfo.api_token, 
              'appid': this.signinfo.appid,
              'timestamp': timestamp,
              'sign': sign
            }
          }).then(res => {
            if (res.data.code == 1000) {
              return
            } else if (res.data.code == 1006) {
              this.notokenLogin(res)
              return
            }
            this.popdio = true
            this.tiptype = false
            this.erromsg = res.data.msg
          }).catch(error => {
            console.log(error)
          })
        }
      }
    },
    getlogin (item) {
      this.getislogin = item
      if (this.getislogin) {
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
          try {
            window.webkit.messageHandlers.nativeLogin.postMessage(null)
          } catch(error) {
            console.log(error)
          }
        }
        if (/(Android)/i.test(navigator.userAgent)) {
          window.nativeJsObject.nativeLogin()
        }
        this.isLogin = false
        return
      }
      this.isLogin = false
    },
    Tosend () {
      // navigator.connection.addEventListener('change', measureBW())
      // alert('网络'+navigator.connection.downlink+','+navigator.connection.rtt+','+navigator.onLine)
      if (this.form.lowcontent == '') {
        return
      }
      // 检查图片是否超过10M
      for (let i=0;i<this.filelist.length;i++) {
        if(this.filelist[i].size > 10240000) {
          alert(this.feedbacksuccess.pictureExceed)
          // alert('单张图片超过10M！')
          return
        }
      }
      this.loadingshow = true
      this.impleNative('tologin')
      setTimeout(() => {
        // this.isLogin = true
      // 0.没有登陆跳转至登录
      if (!this.signinfo.uid || !this.signinfo.api_token) {
        this.loadingshow = false
        this.isLogin = true
        return
      } else {
        this.isLogin = false
      }
      // 2.check
      let timestamp = Date.parse(new Date()).toString().substr(0,10)
      let sign = this.getSign(this.signinfo.appid,this.signinfo.secret,timestamp,this.signinfo.uid,this.signinfo.api_token)
      this.$axios.get('/v2/feedback/check',{
        headers: {
        'uid': this.signinfo.uid,
        'api-token': this.signinfo.api_token, 
        'appid': this.signinfo.appid,
        'timestamp': timestamp,
        'sign': sign,
        'language': this.lancode
        }
      }).then(res => {
        if (res.data.code == 1000) {
          // 允许提交反馈
          if (this.filelist.length > 0) {
            this.preupfeedbackimg()
          } else {
            this.submitFeedback()
          }
          return
        } else if (res.data.code == 1006) {
          this.notokenLogin(res)
          return
        }
        this.loadingshow = false
        this.popdio = true
        this.tiptype = false
        this.erromsg = res.data.msg
      }).catch(error => {
        alert(error)
        this.loadingshow = false
        this.popdio = true
        this.tiptype = false
        this.erromsg = this.feedfaild
        this.feederror = true
      })
      }, 1000)
    },
    // 预签名-上传反馈图片
    preupfeedbackimg () {
      for (let i=0;i<this.filelist.length;i++) {
        let params = {
          userid: this.signinfo.uid,
          username: this.signinfo.uid,
          filename: this.filelist[i].name,
          filesize: this.filelist[i].size,
        }
        let timestamp = Date.parse(new Date()).toString().substr(0,10)
        let sign = this.getSign(this.signinfo.appid,this.signinfo.secret,timestamp,this.signinfo.uid,this.signinfo.api_token)
        this.$axios.get('/v3/cloud/feedbackput_presignurl',{
          params: params,
          headers: {
          'uid': this.signinfo.uid,
          'api-token': this.signinfo.api_token,
          'appid': this.signinfo.appid,
          'timestamp': timestamp,
          'sign': sign,
          'language': this.lancode
          }
        }).then(res => {
          if (res.data.code == 1000) {
            // 上传
            this.upimgconfirm(res.data.data.url,i)
            return
          } else if (res.data.code == 1006) {
            this.notokenLogin(res)
            return
          }
          this.loadingshow = false
          this.popdio = true
          this.tiptype = false
          this.erromsg = res.data.msg
        }).catch(error => {
          alert(error)
          this.loadingshow = false
          this.feederror = true
          this.popdio = true
          this.tiptype = false
          this.erromsg = this.feedfaild
        })
      }
    },
    upimgconfirm (item,i) {
      let urllast = item.split('com.cn/')[1]
      let url = '/aws/' + urllast
      // for (let i=0;i<this.filelist.length;i++) {
      this.$axios.put(url, this.filelist[i],{
        headers: {
          'X-Amz-Tagging': 'username=' + this.signinfo.uid,
          'X-Amz-Server-Side-Encryption': 'AES256',
        }
      }).then(res => {
        if (res.status == 200) {
          if (i == this.filelist.length-1) {
            this.submitFeedback()
          }
          return
        }
        this.loadingshow = false
        this.popdio = true
        this.tiptype = false
        this.erromsg = res.data.msg
      }).catch(error => {
        alert(error)
        this.loadingshow = false
        this.feederror = true
        this.popdio = true
        this.tiptype = false
        this.erromsg = this.feedfaild
      })
      // }
    },
    // 提交反馈
    submitFeedback () {
      // alert('提交反馈'+this.signinfo.product_id+this.signinfo.email+this.form.lowcontent+this.signinfo.api_token+this.signinfo.uid)
      let imgstring = ''
      for (let i=0;i<this.filelist.length;i++) {
        this.imgstringList.push(this.signinfo.appid + '/' + this.signinfo.uid + '/' + this.filelist[i].name)
      }
      imgstring = this.imgstringList.join(',')
      // 3.提交反馈
      let timestamp = Date.parse(new Date()).toString().substr(0,10)
      let sign = this.getSign(this.signinfo.appid,this.signinfo.secret,timestamp,this.signinfo.uid,this.signinfo.api_token)
      this.$axios.post('/v2/feedback/create',{
        // type_id: 1,
        type_id: 20,
        product_id: +this.signinfo.product_id,
        email: this.signinfo.email,
        content: this.form.lowcontent,
        image: imgstring
      },{
        headers: {
        'uid': this.signinfo.uid,
        'api-token': this.signinfo.api_token, 
        'appid': this.signinfo.appid,
        'timestamp': timestamp,
        'sign': sign
        }
      }).then(res => {
        this.imgstringList = []
        if (res.data.code == 1000) {
          this.feedOverStatus = true
          this.loadingshow = false
          this.isOver = true
          this.popdio = true
          this.tiptype = true
          // 反馈成功
          return
        } else if (res.data.code == 1006) {
          this.loadingshow = false
          this.notokenLogin(res)
          return
        }
        // 反馈失败
        this.feedOverStatus = false
        this.loadingshow = false
        this.isOver = true
        this.popdio = true
        this.tiptype = false
        this.erromsg = res.data.msg
      }).catch(error => {
        alert(error)
        this.loadingshow = false
        this.feederror = true
        this.popdio = true
        this.tiptype = false
        this.erromsg = this.feedfaild
      })
    },
    feedbackOver () {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        try {
          window.webkit.messageHandlers.nativeIsRatingsSuccess.postMessage(this.feedOverStatus)
        } catch(error) {
          console.log(error)
        }
      }
      if (/(Android)/i.test(navigator.userAgent)) {
        window.nativeJsObject.nativeIsRatingsSuccess(this.feedOverStatus)
      }
    },
    notokenLogin(item) {
      this.popdio = true
      this.tiptype = false
      this.erromsg = item.data.msg
      // alert('账号多地登录，跳转登录前')
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        try {
          window.webkit.messageHandlers.nativeLogin.postMessage(null)
        } catch(error) {
          console.log(error)
        }
      }
      if (/(Android)/i.test(navigator.userAgent)) {
        window.nativeJsObject.nativeLogin()
      }
    },
    Tocomment () {
      this.isCommen = true
      this.eventTracking('00058','2031','104','','','')
      this.$refs.SendComment.classList.remove('beforsend')
      this.$refs.SendComment.classList.remove('marginsend')
      this.$refs.SendComment.classList.add('aftersend')
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        try {
          window.webkit.messageHandlers.nativeGoAmazon.postMessage(null)
        } catch(error) {
          console.log(error)
        }
      }
      if (/(Android)/i.test(navigator.userAgent)) {
        window.nativeJsObject.nativeGoAmazon()
      }
    },
    // 领取礼物
    isRecieve () {
      this.eventTracking('00059','2031','104','','','')
      this.$router.push('/gift/' + this.checkedsite.id)
      // document.getElementById("popBox").style.display = "block"
      // document.getElementById("popLayer").style.display = "block"
    },
    // layer () {
    //   document.getElementById("popBox").style.display = "none"
    //   document.getElementById("popLayer").style.display = "none"
    // },

    getSign (appid,secret,timestamp,uid,api_token) {
      var str =  appid + timestamp + uid + api_token
      var hash = Cryptojs.HmacSHA256(str, secret)
      var hashInBase64 = hash.toString()
      var jiamistr = Cryptojs.enc.Utf8.parse(hashInBase64)
      var base64 = Cryptojs.enc.Base64.stringify(jiamistr)
      return base64
    },
    // 上传组件值
    getupload (file, dele) {
      if (!dele && this.filelist.length <=3) {
        this.filelist.push(file)
      }
      if (dele) {
        for (let i=0;i< this.filelist.length;i++) {
          if (this.filelist[i].name == dele) {
            this.filelist.splice(i,1)
          }
        }
      }
    },
    // 5分站点选择
    getStation (item) {
      this.checkedsite = item
      this.eventTracking('00057','2031','104','','','')
    },
    GetunGrade () {
      let timestamp = Date.parse(new Date()).toString().substr(0,10)
      let sign = this.getSign(this.signinfo.appid,this.signinfo.secret,timestamp,'','')
      this.$axios.get('/v2/invite_comments/activity',{
        params: {
          activity_product: this.signinfo.camera_model
        },
        headers: {
          'appid': this.signinfo.appid,
          'timestamp': timestamp,
          'sign': sign,
          'language': this.lancode
        }
      }).then(res => {
        if (res.data.code == 1000) {
          this.$nextTick(() => {
            this.giftrate = +res.data.data.reward_grade
            // if (!this.signinfo.activity_id) {
            //   this.signinfo.activity_id = res.data.data.id
            // }
          })
          // alert('评分条件'+this.giftrate)
          return
        }
        this.popdio = true
        this.tiptype = false
        this.erromsg = res.data.msg
      }).catch(error => {
        console.log(error)
      })
    },
    getcontentrequest (item) {
      let timestamp = Date.parse(new Date()).toString().substr(0,10)
      let sign = this.getSign(this.signinfo.appid,this.signinfo.secret,timestamp,'','')
      this.$axios.get('/v2/invite_comments/app_language',{
        params: {
          page_code: item,
          language_code: this.lancode
        },
        headers: {
          'appid': this.signinfo.appid,
          'timestamp': timestamp,
          'sign': sign,
          'language': this.lancode
        }
      }).then(res => {
        if (res.data.code == 1000) {
          return res.data.data
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 页面内容
    getContent () {
      let timestamp = Date.parse(new Date()).toString().substr(0,10)
      let sign = this.getSign(this.signinfo.appid,this.signinfo.secret,timestamp,'','')
      this.$axios.get('/v2/invite_comments/app_language',{
        params: {
          page_code: '00000001',
          language_code: this.lancode
        },
        headers: {
          'appid': this.signinfo.appid,
          'timestamp': timestamp,
          'sign': sign,
          'language': this.lancode
        }
      }).then(res => {
        if (res.data.code == 1000) {
          this.pageContent = res.data.data
        }
      }).catch(error => {
        console.log(error)
      })
      this.$axios.get('/v2/invite_comments/app_language',{
        params: {
          page_code: '00000006',
          language_code: this.lancode
        },
        headers: {
          'appid': this.signinfo.appid,
          'timestamp': timestamp,
          'sign': sign,
          'language': this.lancode
        }
      }).then(res => {
        if (res.data.code == 1000) {
          this.feedbacksuccess = res.data.data
        }
      }).catch(error => {
        console.log(error)
      })
      this.$axios.get('/v2/invite_comments/app_language',{
        params: {
          page_code: '00000007',
          language_code: this.lancode
        },
        headers: {
          'appid': this.signinfo.appid,
          'timestamp': timestamp,
          'sign': sign,
          'language': this.lancode
        }
      }).then(res => {
        if (res.data.code == 1000) {
          this.gologincontent = res.data.data.longintipcontent
          this.gologinsure = res.data.data.surelogin
          this.gologincancel = res.data.data.cancellogin
        }
      }).catch(error => {
        console.log(error)
      })
      this.$axios.get('/v2/invite_comments/app_language',{
        params: {
          page_code: '00000008',
          language_code: this.lancode
        },
        headers: {
          'appid': this.signinfo.appid,
          'timestamp': timestamp,
          'sign': sign,
          'language': this.lancode
        }
      }).then(res => {
        if (res.data.code == 1000) {
          this.feedfaild = res.data.data.tip
        }
      }).catch(error => {
        console.log(error)
      })
      // this.pageContent = await this.getcontentrequest('00000001')
      // this.feedbacksuccess = await this.getcontentrequest('00000006')
      // let gologinmg = await this.getcontentrequest('00000007')
      // this.gologincontent = gologinmg.longintipcontent
      // this.gologinsure = gologinmg.surelogin
      // this.gologincancel = gologinmg.cancellogin
      // let feedfaildmsg = await this.getcontentrequest('00000008')
      // this.feedfaild = feedfaildmsg.feedfaild
    },
    getPhoneMsg (item,isandroid) {
      // alert('版本'+item)
      let data = JSON.parse(item)
      this.signinfo.phone_model = data.phone_model
      this.signinfo.phone_system = data.phone_system
      // this.signinfo.appversion = data.app_version
      if (isandroid == 'Android') {
        if (data.app_version !== '1.2.1') { // 新版本
          this.newversion = true
        } else {
          this.newversion = false
        }
      } else {
        if (data.app_version !== '1.1.5' || data.app_version !== '1.1.4') { // 新版本
          this.newversion = true
        } else {
          this.newversion = false
        }
      }
      this.cansavegrade2 = true
      console.log('手机',this.signinfo.phone_model,this.signinfo.phone_system )
    },
    getLoginMsg (item) {
      // alert('登录信息'+item)
      // alert('username'+item)
      let data = JSON.parse(item)
      this.signinfo.uid = data.uid
      this.signinfo.api_token = data.api_token
      this.signinfo.username = data.username
      this.signinfo.product_id = data.product_id
      this.signinfo.email = data.email
      console.log('login'+this.signinfo.uid+this.signinfo.api_token+this.signinfo.username+this.signinfo.product_id+this.signinfo.email)
    },
    getActivityMsg(item){
      // alert('活动信息'+item)
      let data = JSON.parse(item)
      this.signinfo.activity_id = data.activity_id
      this.signinfo.camera_model = data.camera_model
      this.signinfo.unique_number = data.unique_number
      this.cansavegrade1 = true
      // alert('活动消息'+this.signinfo.activity_id+this.signinfo.camera_model+this.signinfo.unique_number)
      this.GetsiteList()
      // 获取评分
      this.GetunGrade()
    },
    getStarNum (item) {
      if (this.initrating) {
        return
      }
      let data = JSON.parse(item)
      this.rating = Number(data.starNum)
      this.saveScore()
      this.initstar = true
      // this.starNum.forEach(item => {
      //   this.isRating(item)
      //   console.log('渲染星星')
      // });
    },
    getLanguage (item) {
      this.lancode = item
      this.getContent()
    },
    backsaveScore (item) {
      // 保存评分---未登录
      // 登录保存
      let data = JSON.parse(item)
      let timestamp = Date.parse(new Date()).toString().substr(0,10)
      let sign = this.getSign(this.signinfo.appid,this.signinfo.secret,timestamp,data.uid,data.api_token)
      let actid = (data.activity_id+'').split('.')[0]
      this.$axios.post('/v2/invite_comments/user_save_grade',{
        unique_number: data.unique_number,
        camera_model: data.camera_model,
        phone_model: data.phone_model,
        phone_system: data.phone_system,
        grade: this.rating,
        activity_id: +actid
      },{
        headers: {
          'uid': data.uid,
          'api-token': data.api_token, 
          'appid': this.signinfo.appid,
          'timestamp': timestamp,
          'sign': sign
        }
      }).then(res => {
        if (res.data.code == 1000) {
          return
        } else if (res.data.code == 1006) {
          this.notokenLogin(res)
          return
        }
        this.popdio = true
        this.tiptype = false
        this.erromsg = res.data.msg
      }).catch(error => {
        console.log(error)
      })
    },
    // 执行native
    impleNative (item) {
      if (item == 'tologin') {
        this.initrating = true
      } else {
        this.initrating = false
      }
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        try {
          window.webkit.messageHandlers.nativeGetPhoneMsg.postMessage(null)
          window.webkit.messageHandlers.nativeGetLoginMsg.postMessage(null)
          window.webkit.messageHandlers.nativeGetActivityMsg.postMessage(null)
          window.webkit.messageHandlers.nativeLanguage.postMessage(null)
          window.webkit.messageHandlers.nativeGetStarNum.postMessage(null)
        } catch(error) {
          console.log(error)
        }
        window.getPhoneMsg = this.getPhoneMsg
        window.getLoginMsg = this.getLoginMsg
        window.getActivityMsg = this.getActivityMsg
        window.getLanguage = this.getLanguage
        window.getStarNum = this.getStarNum
      }
      if (/(Android)/i.test(navigator.userAgent)) {
        let phonedata = ''
        phonedata = window.nativeJsObject.nativeGetPhoneMsg()
        this.getPhoneMsg(phonedata,'Android')
        let logindata = ''
        logindata = window.nativeJsObject.nativeGetLoginMsg()
        this.getLoginMsg(logindata)
        let activitydata = ''
        activitydata = window.nativeJsObject.nativeGetActivityMsg()
        this.getActivityMsg(activitydata)
        let langue = ''
        langue = window.nativeJsObject.nativeLanguage()
        console.log('语言'+langue)
        this.getLanguage(langue)
        let starnums = ''
        starnums = window.nativeJsObject.nativeGetStarNum()
        console.log('星星'+starnums)
        this.getStarNum(starnums)
      }
    },
    eventTracking(a,b,c,d,e,f) {
      // 进入埋点
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        try {
          window.webkit.messageHandlers.nativeEventTracking.postMessage(a,b,c,d,e,f)
        } catch(error) {
          console.log(error)
        }
      }
      if (/(Android)/i.test(navigator.userAgent)) {
        window.nativeJsObject.nativeEventTracking(a,b,c,d,e,f)
      }
    }
  },
  filters: {},
  mounted () {
    window.backsaveScore = this.backsaveScore
    // 先调用app
    this.impleNative()
    // eventid,pageid,moduleid,e1,e2,e3
    this.eventTracking('00050','2030','104','','','') //评分页
    // this.eventTracking('00051','2030','104','','','')      //评分页  退出反馈---app

    // this.eventTracking('00052','2030','104','','','')     //评分页  评分成功询问是否退出---app
    // this.eventTracking('00053','2030','104','','','')    //评分页  评分成功页_点击确认退出----app
    // this.eventTracking('00054','2030','104','','','')    //评分页  评分成功页_点击继续填单

    // this.eventTracking('00056','2031','104','','','') 评分成功页_五星提示引导AMZ好评，选了五星后提示出现时
    // this.eventTracking('00057','2031','104','','','') 评分成功页_切换了站点
    // this.eventTracking('00057','2031','104','','','') 评分成功页_切换了站点
    // this.eventTracking('00058','2031','104','','','') 评分成功页_跳转到亚马逊，跳转时
    // this.eventTracking('00059','2031','104','','','') 评分成功页_点击已好评去领礼

    // this.eventTracking('00061','2033','104','','','') 申请礼品_进入时
    // this.eventTracking('00060','2033','104','','','') 评分成功页_选择了礼物，点击已选择礼物时？？？？
    // this.eventTracking('00062','2033','104','','','') 申请礼品_提交出现结果时
    // this.eventTracking('00063','2033','104','','','') 申请礼品_中途退出时，未提交并完全退出时----app
  },
  created () {
  }
}
</script>

<style scoped>
.container {
  height: 100%;
  width: 100%;
  padding: 0 10px;
  max-width: 345px;
  margin: 0 auto
}
.title {
 font-family: Avenir ;
 font-weight: 900;
 font-style: italic;
 font-size: 40px;
 line-height: 54.64px;
 color: white;
}
.star {
  margin-top: 30px;
  display: flex;
  width: 100%;
  margin: 30px auto 0;
}
.star li:first-child{
  /* margin-left: 52px; */
  width: 30px;
  height: 29px;
}
.star li{
  margin-left: 30px;
  width: 30px;
  height: 29px;
}
.star-item {
    display: inline-block;
    width: 30px;
    height: 29px;
    background-image: url('../assets/image/ic_use_star_off@3x.png');
    background-size: cover;
  }
  .star-item:last-child {
    padding-right: 0;
  }
  .star-active {
    background-image: url('../assets/image/ic_use_star_on@3x.png');
    background-position: center 29px;
    background-size: cover;
  }
  .lessfive {
    width: 335px;
    font-family: Avenir;
    font-size: 15px;
    font-style: italic;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    color: #fff;
    text-align: center;
    margin: 25px auto 31px;
  }
  .content {
    /* //styleName: 拉丁语系/正文; */
    font-family: Avenir;
    font-size: 15px;
    font-style: italic;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: rgb(145, 148, 154);
    /* opacity: 0.5; */
    width: 100%;
    height: 102px;
    background-color: #232A35;
    padding: 15px;
    margin-bottom: 20px;
    outline: none;
    border-radius: 3px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box
  }
  .pictip {
    color: #fff;
    font-family: Avenir;
    font-size: 15px;
    font-style: italic;
    font-weight: 800;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    width: 100%;
    margin: 0 auto 20px
  }
  .upimg {
    text-align: left;
    margin-left: 20px;
    /* // background-color #232A35 */
    margin-bottom: 145px;
  }
  .send {
    width: 100%;
    height: 55px;
    border: none; 
    /* //styleName: 拉丁语系/主按钮; */
    font-family: Avenir;
    font-size: 16px;
    font-style: italic;
    font-weight: 900;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    color: #fff;
    border-radius: 3px;
  }
  .marginsend {
    margin-bottom: 40px;
  }

  .nofullsend {
    background-color: #2B323D;
  }
  .sendcolor {
    background-color: #FD6700;
  }


  .beforsend {
    background-color :#FD6700;
    margin-top: 149px;
  }
  .aftersend {
    margin-top: 79px;
    background-color :#1C222D;
  }

  .gift {
    background-color :#FD6700;
    margin-top: 15px;
  }


  /* // 5分 */
  .fivetip {
    font-family: Avenir;
    font-size: 28px;
    font-style: italic;
    font-weight: 900;
    line-height: 38px;
    letter-spacing: 0px;
    text-align: center;
    color: #FD6700;
    margin-top: 20px;
  }
  .guidamazon {
    margin: 10px 15px 0 15px;
    color: #fff;
    font-family: Avenir;
    font-size: 15px;
    font-style: italic;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
  }

  /* // 弹出框 */
  /* // 背景层 */
  #popLayer {
    height: 812px;
    display: none;
    background-color: rgb(16, 21, 30);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    /* -moz-opacity: 0.8;
    opacity: 0.8; */
  }
  /*弹出层*/
  #popBox {
    display: none;
    background-color: #FFFFFF;
    z-index: 11;
    width: 300px;
    height: 215px;
    position:fixed;
    top:0;
    right:0;
    left:0;
    bottom:0;
    margin:auto;
    border-radius: 10px;
  }
  #popBox .close{
      text-align: right;
      margin-right: 5px;
      background-color: #F8F8F8;
  }
  /*关闭按钮*/
  #popBox .close a {
      text-decoration: none;
      color: #2D2C3B;
  }

  .popimg {
    width: 40px;
    height: 40px;
    margin-top: 25px;
  }
  .poptip {
    /* //styleName: 拉丁语系/正文; */
    font-family: Avenir;
    font-size: 15px;
    font-style: italic;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    margin: 0 15px;
    color: #0E131B;
  }
  .popbutton {
    position: absolute;
    bottom: 0;
  }
  .button {
    border: none;
    border-top: 1px solid rgba(14,19,27,10%);
    background-color: #fff;
    width: 150px;
    height: 50px;
    /* //styleName: 拉丁语系/主按钮; */
    font-family: Avenir;
    font-size: 14px;
    font-style: italic;
    font-weight: 900;
    line-height: 50px;
    letter-spacing: 0px;
    text-align: center;
  }
  .rightbutton {
    font-size: 14px;
    border-left: 1px solid rgba(14,19,27,10%);
    color: #FD6700;
  }

  /* // 上传 */
  .uploadfa {
    margin-bottom: 145px;
  }
  /* // 站点选择 */
  .stationselect {
    margin-top: 38px;
  }
</style>
