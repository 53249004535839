<template>
  <div class="">
    <div id="dioLayer">
        <!-- <span style="position: relative;top: 50%;color:rgb(104, 117, 117)">loading...</span> -->
        <img class="loadingimg" style="position: relative;top: 50%;width: 100px" src="../assets//image/ic_camera_loading_inside.png" alt="">
    </div>

  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  filters: {},
  mounted () {
    document.getElementById("dioLayer").style.display = "block"
  },
  created () { }
}
</script>

<style scoped>
 #dioLayer {
    /* height: 812px; */
    display: none;
    background-color: rgba(16, 21, 30,0.8);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    text-align: center;
    line-height: 100%;
  }
  .loadingimg {
    animation: rotate 3s linear infinite;
  }
  @keyframes rotate{
    from{transform: rotate(360deg)}
    to{transform: rotate(0deg)}
  }
</style>
